import * as React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import Seo from "../components/SEO"
import Button from "../components/Button/Button"
import { BlogSingleStyles } from "../components/Info/InfosStyles"


const about = () => {
  return (
    <>
      <Seo title="msgreply" />
      <Layout>
         <BlogSingleStyles>
            <h1 className="blogsingle__title">Съобщението е изпратено !!!</h1>           
            <article className="blogsingle__content">
              <p>Благодарим за съобщението Ви! Скоро ще Ви отговорим.</p>
              <div className="blogsingle__back">
              <Button text="Към начална страница" as={Link} to="/" />
              </div>
            </article>
          </BlogSingleStyles>
      </Layout>
    </>
  )
}

export default about
